import { faArrowLeft, faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Container, Card, Col, Row, Button, FloatingLabel, Form, DropdownButton, Dropdown, Image, Alert } from "react-bootstrap";
import toast from "react-hot-toast";
import { useIndexedDB } from "react-indexed-db";
import { Link, useNavigate } from "react-router-dom";
import Auth from "../../auth/auth";
import home_logo from "../../assets/icons/ICRI_NEW.jpeg";
// import LoadCache from "../../cache/Load.cache";
import fingerPrint from "../../helpers/fingerprintjs";
import { AuthService } from "../../Services/authServices";
import axios from "axios";
import PublicService from "../../Services/public.service";
import { url } from "inspector";
import { addDataToCache, checkIfInCache, deleteAllCaches, downloadAndStoreToCache, downloadAndStoreToCacheAsArrayBuffer, storeAsArrayBuffer } from "../../cache/Load.cache";
import CustomModal from "../../components/Modal/Modal";

interface ILogin {
  counter: any
}

export default function Login({ counter }: ILogin) {

  const navigate = useNavigate();
  const [dicomCount, setdicomCount] = useState<number>(0);
  const [loginData, setLoginData] = useState<any>({
    email: "",
    password: "",
  });

  // counter states
  const [questionCounter, setquestionCounter] = useState(0);
  const [examCounter, setexamCounter] = useState(0);
  const [totalExam, settotalExam] = useState(0);
  const [totalQuestions, settotalQuestions] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const onChangeValue = (e: any) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  const loginSubmit = async (e: any) => {
    if (loading) {
      setShowModal(true)
      return;
    }
    e.preventDefault();

    await AuthService.login(({
      email: loginData.email,
      password: loginData.password,
      devInfo: await fingerPrint()
    }))
      .then(async (res) => {
        if (res.status === 200) {
          await Auth.authenticate();
          localStorage.setItem("authKey", res.data.data.token)
          toast.success("Login Success")
          navigate('/exam/student-info')
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
          console.error(err);
          toast.error("Something went wrong")
        } else if (err.response.status === 500) {
          // alert("Alredy Loged in")
          toast.error(err.response.data)

          console.error(err.response.data);
        }
      })
  };

  // // store data to cache
  // const storeToCache = async () => {
  //   setLoading(true);
  //   let exams = [];
  //   const res = await PublicService.getAllLiveExams();
  //   if (res.status === 200) {
  //     exams = res.data.map(data => data._id);
  //   }

  //   if (exams.length === 0) {
  //     console.log("No exams found");
  //     return;
  //   }

  //   settotalExam(exams.length);
  //   let examCounter = 0;
  //   setexamCounter(examCounter);

  //   for (const exam of exams) {
  //     let totalImages = 0;
  //     let imageCounter = 0;
  //     setquestionCounter(0);
  //     const res = await PublicService.getQuestionDataByExamId(exam);
  //     if (res.status === 200) {
  //       localStorage.setItem("onlineUrl", res.data?.s3Url);

  //       totalImages = res.data.thumbnails.length + res.data.links.length;
  //       settotalQuestions(totalImages);

  //       if (res.data.links.length > 0) {
  //         if (localStorage.getItem('serverType') == 'offline') {
  //           // let testLinks = res.data.links.slice(0, 5);
  //           let localServerUrl = localStorage.getItem('localServerUrlType') + '://' + localStorage.getItem('localServerUrl');

  //           for (const link of res.data.links) {
  //             await PublicService.getFileFromLocalServer(localServerUrl, link)
  //               .then(async (res) => {
  //                 if (res.status === 200) {
  //                   const isCached = await checkIfInCache(link);
  //                   console.log(isCached, "check if in cache- ");
  //                   if (!isCached) {
  //                     console.log("not in cache- ", link);
  //                     console.log(res, "res.data");
  //                     // console.log(res.data?.[0]?.resp, "res.data?.[0]?.resp");
  //                     await storeAsArrayBuffer(res.data?.[0]?.resp, link);
  //                   }
  //                   imageCounter = imageCounter + 1;
  //                   setquestionCounter(imageCounter);
  //                 }
  //               })
  //               .catch((error) => {
  //                 console.error("Error during setLocalServer request: ", error);
  //               });
  //           }
  //         } else {
  //           for (let i = 0; i < res.data.links.length; i += 10) {
  //             const batch = res.data.links.slice(i, i + 10);
  //             const promises = [];
  //             for (const link of batch) {
  //               promises.push(downloadAndStoreToCacheAsArrayBuffer(link))
  //             }
  //             await Promise.all(promises)
  //             imageCounter = imageCounter + promises.length;
  //             setquestionCounter(imageCounter)
  //           }
  //         }
  //       }
  //       console.log("Downloading Links - Complete");

  //       console.log("Downloading Thumbnails");
  //       if (res.data.thumbnails.length > 0) {
  //         if (localStorage.getItem('serverType') == 'offline') {
  //           let promises = [];
  //           let localServerUrl = localStorage.getItem('localServerUrlType') + '://' + localStorage.getItem('localServerUrl');
  //           for (const link of res.data?.thumbnails) {
  //             promises.push(PublicService.getFileFromLocalServer(localServerUrl, link))
  //           }
  //           await Promise.all(promises)
  //           imageCounter = imageCounter + promises.length;
  //           setquestionCounter(imageCounter);
  //         } else {
  //           for (let i = 0; i < res.data.thumbnails.length; i += 10) {
  //             const batch = res.data.thumbnails.slice(i, i + 10);
  //             const promises = [];
  //             for (const link of batch) {
  //               promises.push(downloadAndStoreToCache(link))
  //             }
  //             await Promise.all(promises)
  //             imageCounter = imageCounter + promises.length;
  //             setquestionCounter(imageCounter)
  //           }
  //         }
  //       }
  //       console.log("Downloading Thumbnails - Complete");

  //       examCounter += 1;
  //       setexamCounter(examCounter);
  //     }
  //   }
  //   setLoading(false);
  // }

  // useEffect(() => {
  //   storeToCache();
  // }, []);
  return (
    <>
      <div style={{ height: "100vh", backgroundColor: '#eeeeee' }}>
        <Container style={{ maxWidth: "400px" }} >
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h1 className="text-center font-weight-bold xcn-login-header pt-3">
              <img src={home_logo} height="70px" />
            </h1>
            <h3 className="text-center font-weight-bold">Welcome to CBT Examination</h3>
            <p className="text-center mt-2">Sign In CBT Exam</p>
          </div>


          <Row className="justify-content-md-center mt-4">
            <Col md="12">
              <Card className="p-4 border-0 shadow">
                <>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="User Name"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Username"
                      name="email"
                      onChange={onChangeValue}
                    />
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingPassword"
                    label="Password"
                    onChange={onChangeValue}
                  >
                    <Form.Control type="password" placeholder="Password" name="password" />
                  </FloatingLabel>
                </>
                <Button
                  variant="primary"
                  type="submit"
                  className="mt-4"
                  size="lg"
                  onClick={loginSubmit}
                >
                  <span className="xcn-text-14">
                    Login
                  </span>
                </Button>
              </Card>
            </Col>
          </Row>
          {/* <hr /> */}
          {/* <Row className="mt-3">
            <Col>
              <Form.Group>
                <Form.Label>Online Server URL</Form.Label>
                <Form.Control defaultValue={localStorage.getItem("onlineUrl") || ""} readOnly />
              </Form.Group>
            </Col>
          </Row> */}


          {/* <Row className="mt-3">
            <Col>
              <Form.Group>
                <Form.Label>Local Server URL</Form.Label>
                <div className="d-flex align-items-center">
                  <Form.Control type="text" defaultValue={localStorage.getItem("localUrl") || ""} onChange={(e: any) => setLocalUrl(e.target.value)} />
                  <Button className="ms-3" variant="secondary" onClick={handleClear}>Clear</Button>
                </div>
              </Form.Group>
              <Button className="mt-3" style={{ float: "right" }} onClick={handleLocalServer}>Set URL</Button>
            </Col>
          </Row> */}

          <Row className="mt-3">
            <Col>
              <p><Link to="/authentication"><FontAwesomeIcon icon={faArrowLeft} />  Back to Authentication page</Link>  </p>
              {
                localStorage.getItem('serverType') === 'offline' ?
                  <>
                    <Alert variant="success" className='mt-2'>
                      <Alert.Heading>Using Offline Server:</Alert.Heading>
                      <p className="mb-0">You are using offline server.</p>
                      <p>Server url: <span className='fw-bold fst-italic'>{localStorage.getItem('localServerUrlType')}://{localStorage.getItem('localServerUrl')}</span> </p>
                      <Link to="/authentication"> Click here to edit server url</Link>
                    </Alert>
                  </>
                  :
                  <>
                    <Alert variant="danger" className='mt-2'>
                      <Alert.Heading>Using Online Server:</Alert.Heading>
                      <p>Using online server will result in higher consumption of network bandwidth. Please ensure your internet connection is stable.</p>
                    </Alert>
                  </>
              }
            </Col>
          </Row>
          {/* <div style={{ position: 'fixed', bottom: "50px", right: "10px" }} className="me-2">
            <div className="bg-white">
              <span>
                Exam:  {examCounter}
                <br />
                Index: {dicomCount}
                <br />
                Image/Data Counter: {questionCounter}
              </span>
            </div>
            {questionCounter === totalQuestions && examCounter === totalExam ?
              "Ready" : <>Downloading {questionCounter + " of " + totalQuestions} Images in {examCounter + " of " + totalExam} Exam </>
            }
          </div> */}
        </Container>
      </div>

      <CustomModal
        displayModal={showModal}
        onClose={() => setShowModal(false)}
        modalBody={
          <>
            <Alert variant="warning">
              <Alert.Heading>Download In Progress</Alert.Heading>
              <p>Downloading {questionCounter + " of " + totalQuestions} Images, please wait ...</p>
            </Alert>
          </>
        }
      />
    </>
  );
};

